import { NotificationType } from '@local/web-design-system';
import { Dispatch } from 'redux';
import { CLIENT_SIDE_PAGINATION_LIMIT } from 'state-domains/constants';
import { downloadFile } from 'state-domains/domain/utils';
import { getSessionId } from 'src/utilities';

import {
    ADD_SNACKBAR_NOTIFICATION,
    CLEAR_JOB_LIST,
    LIST_JOBS,
    SET_JOB_SEARCH_TERM,
    CREATE_OR_UPDATE_JOB,
} from '../../../types/actionTypes';
import {
    convertToCamel,
    isValidSecureUrl,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { jobShim } from '../shim';
import { Job, JobCompleteEvent, JobExportType } from '../types';

const loadJobList =
    (userId?: string, statuses = ['all']) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(LIST_JOBS) });
        jobShim.loadJobList(statuses, userId).subscribe({
            next: (jobsList: Job[]) =>
                dispatch({
                    type: typeComplete(LIST_JOBS),
                    payload: jobsList,
                }),
            error: (error: Error) => {
                dispatch({ type: typeFail(LIST_JOBS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearJobList = () => (dispatch: Dispatch) => dispatch({ type: typeComplete(CLEAR_JOB_LIST) });

const setJobSearchTerm =
    (searchTerm: string, offset = 0, limit: number = CLIENT_SIDE_PAGINATION_LIMIT) =>
    (dispatch: Dispatch) =>
        dispatch({
            type: typeComplete(SET_JOB_SEARCH_TERM),
            payload: { searchTerm, offset, limit },
        });

const updateJob = (data: string) => (dispatch: Dispatch) => {
    const payload = convertToCamel(JSON.parse(data)) as Job;
    dispatch({ type: CREATE_OR_UPDATE_JOB, payload });
};

const completeJob = (data: string) => {
    const event = convertToCamel(JSON.parse(data)) as JobCompleteEvent;
    if (
        event.detail === JobExportType.Export ||
        event.detail === JobExportType.ListExport ||
        event.detail === JobExportType.TableExport
    ) {
        const sessionId = getSessionId();

        if (isValidSecureUrl(event.referenceDetail) && event.detail1 === sessionId) {
            downloadFile(event.referenceDetail);
        }
    }
};

export const actions = {
    loadJobList,
    updateJob,
    clearJobList,
    setJobSearchTerm,
    completeJob,
};
